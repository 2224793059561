import { deleteApiWrapper, getApiWrapper, postApiWrapper, putApiWrapper } from "./token-wrapper-function";
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
/**
 * Retrieves data of users.
 * @returns {Array} An array of user objects.
 */


/**
 * Retrieves all users.
 * @returns {Array} An array of user objects.
 */

const base_url = 'https://y71-dashboard.y-71.com'

export const getAllOrganisations = async() => {
  const response = await getApiWrapper(`${base_url}/get-organisations`);
  console.log('getAllOrganisations', response);
  return response;
};



export const createOrganisation = async (org) => {
  const response = await postApiWrapper(`${base_url}/create-org`, org);
  console.log('createOrganisation', response);
  return response;
}

export const updateOrganisation = async (org, id) => {
  console.log("updating org from here", org)
  const response = await putApiWrapper(`${base_url}/update-organisation/`+id, org);
  return response;
} 

export const deleteOrganisation = async (id) => {
  const response = await deleteApiWrapper(`${base_url}/delete-organization/${id}`);
  return response;
}

export const getCurrentOrganisationUsers = async(id) => {
  const response = await getApiWrapper(`${base_url}/get-current-organisation-users/${id}`);
  return response;
}


export const getOrganisationDetails = async(id) => {
    const response = await getApiWrapper(`${base_url}/get-organisation-detail/${id}`);
    return response;
  }


  export const createOrganisationUser = async (orgId, user) => {
    const response = await postApiWrapper(`${base_url}/create-org-user/${orgId}`, user);
    console.log('createOrganisation', response);
    return response;
  }


export const updateOrganisationUser = async (userId, user) => {
  const response = await putApiWrapper(`${base_url}/update-org-user/${userId}`, user);
  console.log('createOrganisation', response);
  return response;
}

export const getOrgUserById = async(id) => {
  const response = await getApiWrapper(`${base_url}/get-organisation-user/${id}`);
  return response;
}

